import { motion } from "framer-motion";
import Image from "next/image";
import { Tilt } from "react-next-tilt";

import { fadeIn, introSummary, services, textVariant } from "@/constants";
import { useComponentOnScreen } from "@/utils/hooks";
import { styles } from "@/utils/styles";

import SectionWrapper from "@/components/modules/wrapper/SectionWrapper";
import { StaticImageData } from "next/image";
import { cn } from "@/utils";

type ServiceCardProps = {
	index: number;
	title: string;
	content: string;
	icon: StaticImageData;
};

const ServiceCard = ({ index, title, content, icon }: ServiceCardProps) => {
	return (
		<Tilt className="mt-0 w-full h-full">
			<motion.div
				variants={fadeIn("right", "spring", 0.5 * index, 0.5)}
				className="w-full h-full p-[1px] rounded-[20px] shadow-card"
			>
				<div className="bg-gray-800 bg-opacity-50 backdrop-blur-md rounded-[20px] py-5 px-12 h-full lg:min-h-[320px] xl:min-h-[280px] flex items-center flex-col">
					<div className="w-full flex justify-start items-center gap-4 mb-4">
						<span className="text-[20px] font-bold text-center text-black bg-yellow-500 rounded-full leading-[3rem] h-12 w-12 ">
							{index + 1}
						</span>
						<h3 className="text-white text-[20px] font-bold text-center flex">
							{title}
						</h3>
					</div>
					<div className="relative items-center">
						<Image
							className="float-end object-contain"
							src={icon.src}
							alt={title}
							width={100}
							height={100}
						/>
						<p>{content}</p>
					</div>
				</div>
			</motion.div>
		</Tilt>
	);
};

const About = () => {
	const ref = useComponentOnScreen("about");
	return (
		<section id="about" ref={ref}>
			<div className="rounded-xl bg-gray-800 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
				<motion.div variants={textVariant()}>
					<p className={styles.sectionSubText}>Who Am I</p>
					<h2 className={styles.sectionHeadText}>Introduction</h2>
				</motion.div>

				<motion.p
					variants={fadeIn("", "", 0.1, 1)}
					className={cn(
						styles.paragraphText, 
						"mt-4 max-w-5xl"
					)}
				>
					{introSummary}
				</motion.p>
			</div>

			<div className="mt-20 sm:grid hidden lg:grid-cols-2 gap-10">
				{services.map((service, index) => (
					<ServiceCard key={service.title} index={index} {...service} />
				))}
			</div>
		</section>
	);
};

export default SectionWrapper(About, "about");
