"use client";

import React, { useState } from "react";
import { motion } from "framer-motion";
import { cn } from "@/utils";
import Image from "next/image";
import { github as githubicon, video as videoicon, webicon } from "@/assets";
import { Tag } from "@/constants";

const colorsGradients = {
  blue: "text-blue-200",
  green: "text-green-200",
  yellow: "text-yellow-200",
  orange: "text-orange-200",
};

export const PinContainer = ({
  children,
  title,
  href,
  github,
  video,
  description,
  tags,
  className,
  containerClassName,
}: {
  children: React.ReactNode;
  title?: string;
  href?: string;
  github?: string;
  video?: string;
  description: string;
  tags: Tag[];
  className?: string;
  containerClassName?: string;
}) => {
  const [transform, setTransform] = useState(
    "translate(-50%,-50%) rotateX(0deg)"
  );

  const onMouseEnter = () => {
    setTransform("translate(-50%,-50%) rotateX(40deg) scale(0.8)");
  };
  const onMouseLeave = () => {
    setTransform("translate(-50%,-50%) rotateX(0deg) scale(1)");
  };

  return (
    <div
      className={cn(
        "relative group/pin",
        containerClassName
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        style={{
          perspective: "1000px",
          transform: "rotateX(70deg) translateZ(0deg)",
        }}
        className="absolute left-1/2 top-1/2 ml-[0.09375rem] mt-4 -translate-x-1/2 -translate-y-1/2"
      >
        <div
          style={{
            transform: transform,
          }}
          className="absolute left-1/2 p-4 top-1/2 flex justify-start items-start rounded-2xl shadow-[0_8px_16px_rgb(0_0_0/0.4)] group-hover/pin:border-white/[0.2] transition duration-700 overflow-hidden bg-gray-800 bg-opacity-70 backdrop-blur-md"
        >
          <div className={cn(" relative z-50 ", className)}>{children}</div>
        </div>
      </div>
      <PinPerspective title={title} github={github} video={video} href={href} tags={tags} description={description} />
    </div>
  );
};

export const PinPerspective = ({
  title,
  href,
  github,
  video,
  tags,
  description,
}: {
  title?: string;
  href?: string;
  github?: string;
  video?: string;
  tags: Tag[];
  description: string;
}) => {
  return (
    <motion.div className="w-96 h-80 flex items-center justify-center opacity-0 group-hover/pin:opacity-100 z-[100] transition duration-500">
      <div className=" w-full h-full -mt-7 flex-none  inset-0">

        {href && (
          <div
            onClick={() => window.open(href, href.startsWith("/") ? "_self" : "_blank")}
            onKeyDown={() => window.open(href, "_blank")}
            className="absolute top-0 inset-x-0 flex justify-center cursor-pointer z-[100]"
          >

            <div className="relative flex space-x-2 items-center rounded-full bg-zinc-950 py-0.5 px-4 ring-1 ring-white/10 "
            >
              <Image
                src={webicon.src}
                alt="url-link"
                className="w-4 h-4 object-contain"
                width={20}
                height={20}
              />
              <span className="relative z-20 text-white text-xs font-bold inline-block py-0.5">
                {title}
              </span>

              <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover/btn:opacity-40"></span>
            </div>
          </div>
        )}

        {github && (
          <div
            onClick={() => window.open(github, "_blank")}
            onKeyDown={() => window.open(github, "_blank")}
            className="absolute top-10 inset-x-0 flex justify-center cursor-pointer z-[100]"
          >
            <div className="relative flex space-x-2 items-center rounded-full bg-zinc-950 py-0.5 px-4 ring-1 ring-white/10 "
            >
              <Image
                src={githubicon.src}
                alt="github"
                className="w-4 h-4 object-contain"
                width={20}
                height={20}
              />
              <span className="relative z-20 text-white text-xs font-bold inline-block py-0.5">
                {github}
              </span>

              <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover/btn:opacity-40"></span>
            </div>
          </div>
        )}

        {video && (
          <div
            onClick={() => window.open(video, "_blank")}
            onKeyDown={() => window.open(video, "_blank")}
            className="absolute top-0 inset-x-0 flex justify-center cursor-pointer z-[100]"
          >
            <div className="relative flex space-x-2 items-center rounded-full bg-zinc-950 py-0.5 px-4 ring-1 ring-white/10 "
            >
              <Image
                src={videoicon.src}
                alt="video"
                className="w-4 h-4 object-contain"
                width={20}
                height={20}
              />
              <span className="relative z-20 text-white text-xs font-bold inline-block py-0.5">
                {video}
              </span>

              <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover/btn:opacity-40"></span>
            </div>
          </div>
        )}

        <div className="absolute top-1/2 -translate-y-1/2 flex flex-col rounded-xl bg-gray-900 bg-opacity-50 px-10 py-2 shadow-lg backdrop-blur-md z-[100]">
          <p className="text-white text-base font-medium inline-block py-0.5">{description}</p>
          <div className="flex flex-wrap gap-2 mt-4">
            {tags.map(({ name, color }) => {
              return (
                <p
                  key={name}
                  className={cn("text-[14px]", colorsGradients[color])}
                >
                  #{name}
                </p>
              );
            })}
          </div>
        </div>

        <div
          style={{
            perspective: "1000px",
            transform: "rotateX(70deg) translateZ(0)",
          }}
          className="absolute left-1/2 top-1/2 ml-[0.09375rem] mt-4 -translate-x-1/2 -translate-y-1/2"
        >
          <>
            <motion.div
              initial={{
                opacity: 0,
                scale: 0,
                x: "-50%",
                y: "-50%",
              }}
              animate={{
                opacity: [0, 1, 0.5, 0],
                scale: 1,

                z: 0,
              }}
              transition={{
                duration: 6,
                repeat: Infinity,
                delay: 0,
              }}
              className="absolute left-1/2 top-1/2  h-[11.25rem] w-[11.25rem] rounded-[50%] bg-sky-500/[0.08] shadow-[0_8px_16px_rgb(0_0_0/0.4)]"
            ></motion.div>
            <motion.div
              initial={{
                opacity: 0,
                scale: 0,
                x: "-50%",
                y: "-50%",
              }}
              animate={{
                opacity: [0, 1, 0.5, 0],
                scale: 1,

                z: 0,
              }}
              transition={{
                duration: 6,
                repeat: Infinity,
                delay: 2,
              }}
              className="absolute left-1/2 top-1/2  h-[11.25rem] w-[11.25rem] rounded-[50%] bg-sky-500/[0.08] shadow-[0_8px_16px_rgb(0_0_0/0.4)]"
            ></motion.div>
            <motion.div
              initial={{
                opacity: 0,
                scale: 0,
                x: "-50%",
                y: "-50%",
              }}
              animate={{
                opacity: [0, 1, 0.5, 0],
                scale: 1,

                z: 0,
              }}
              transition={{
                duration: 6,
                repeat: Infinity,
                delay: 4,
              }}
              className="absolute left-1/2 top-1/2  h-[11.25rem] w-[11.25rem] rounded-[50%] bg-sky-500/[0.08] shadow-[0_8px_16px_rgb(0_0_0/0.4)]"
            ></motion.div>
          </>
        </div>

        <>
          <motion.div className="absolute right-1/2 bottom-1/2 bg-gradient-to-b from-transparent to-cyan-500 translate-y-[14px] w-px h-20 group-hover/pin:h-40 blur-[2px]" />
          <motion.div className="absolute right-1/2 bottom-1/2 bg-gradient-to-b from-transparent to-cyan-500 translate-y-[14px] w-px h-20 group-hover/pin:h-40  " />
          <motion.div className="absolute right-1/2 translate-x-[1.5px] bottom-1/2 bg-cyan-600 translate-y-[14px] w-[4px] h-[4px] rounded-full z-40 blur-[3px]" />
          <motion.div className="absolute right-1/2 translate-x-[0.5px] bottom-1/2 bg-cyan-300 translate-y-[14px] w-[2px] h-[2px] rounded-full z-40 " />
        </>
      </div>
    </motion.div >
  );
};
