/**
 *
 * @file experience.ts
 *
 * @description This file contains the experience data for the portfolio website.
 *  - experiences: An array of objects containing the developer's work experiences.
 *
 */

import { bladeclub, ciccc, cozycot, nyp } from "@/assets";

export const experiences = [
	{
		id: crypto.randomUUID(),
		title: "Web Developer and Designer / Mentor",
		company_name: "Cornerstone Tech Inc",
		location: "Vancouver, Canada",
		icon: ciccc,
		iconBg: "#E6DEDD",
		date: "May 2020 - Present",
		points: [
			"Reduced Human Resources’ manual workload by 80% by creating an emailer app with Next.js, automating data entry from Excel spreadsheet, streamlining internal communication.",
			"Successfully taught over 400 aspiring developers from zero to hero, with a track record of over 95% of my students landing jobs in the tech industry.",
			"Developed comprehensive project-based course materials, covering HTML, CSS, JavaScript, React.js, Node.js, and other web technologies.",
		],
	},
	{
		id: crypto.randomUUID(),
		title: "Systems Software Developer",
		company_name: "Blade Club",
		location: "Singapore",
		icon: bladeclub,
		iconBg: "#E6DEDD",
		date: "Feb 2009 - Jul 2018",
		points: [
			"Created and developed websites with Ruby on Rails framework/WordPress, and mobile games with LUA, GameSalad, and Titanium.",
		],
	},
	{
		id: crypto.randomUUID(),
		title: "Graphic Designer",
		company_name: "CozyCot",
		location: "Singapore",
		icon: cozycot,
		iconBg: "#E6DEDD",
		date: "Jun 2008 - Dec 2008",
		points: [
			"Designed online banners, edited videos, and created landing pages, micro-sites, and promotional emails based on client requests for CozyCot, an online portal for women's beauty, fashion, lifestyle, and shopping.",
		],
	},
	{
		id: crypto.randomUUID(),
		title: "Development Technologist",
		company_name: "Nanyang Polytechnic",
		location: "Singapore",
		icon: nyp,
		iconBg: "#E6DEDD",
		date: "May 2007 - May 2008",
		points: [
			"Developed applications with digital content to showcase a touch-less interactive technology called Ubiq’window, and tested the technology as part of an Industrial Project Development for external companies.",
		],
	},
];
