import { motion } from "framer-motion";
import Link from "next/link";

import SectionWrapper from "@/components/modules/wrapper/SectionWrapper";
import { userDetails } from "@/constants";
import { useComponentOnScreen } from "@/utils/hooks";
import { fadeIn } from "@/utils/motion";
import { styles } from "@/utils/styles";

const Contact = () => {
	const contactSubText =
		"sm:text-[14px] text-[12px] text-secondary uppercase tracking-wider";
	const contactHeadText =
		"transition-all hover:text-orange-400 text-[#ffe222] font-black md:text-[30px] sm:text-[20px] xs:text-[14px] text-[12px] mt-2";

	const ref = useComponentOnScreen("contact");

	return (
		<div className="h-screen flex justify-center items-center">
			<motion.section
				variants={fadeIn("right", "spring", 0.5, 0.5)}
				id="contact"
				ref={ref}
			>
				<div className="rounded-xl bg-gray-900 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8 h-full">
					<p className={styles.sectionSubText}>Get in touch</p>
					<h3 className={styles.sectionHeadText}>Contact.</h3>

					<div className="mt-20 flex flex-col gap-8">
						{userDetails.email && (
							<div>
								<p className={contactSubText}>Email me</p>
								<h2 className={contactHeadText}>
									<a
										href={`mailto:${userDetails.email}`}
										target="_blank"
										title="email address"
										rel="noreferrer noopener"
										aria-label="Email Address"
									>
										{userDetails.email}
									</a>
								</h2>
							</div>
						)}

						{userDetails.github && (
							<div>
								<p className={contactSubText}>Github</p>
								<h2 className={contactHeadText}>
									<a
										href={`${userDetails.github}`}
										title="github link"
										target="_blank"
										rel="noreferrer noopener"
										aria-label="Github link"
									>
										{userDetails.github}
									</a>
								</h2>
							</div>
						)}

						{userDetails.linkedin && (
							<div>
								<p className={contactSubText}>LinkedIn</p>
								<h2 className={contactHeadText}>
									<Link
										href={`${userDetails.linkedin}`}
										title="linkedin link"
										target="_blank"
										rel="noreferrer noopener"
										aria-label="LinkedIn link"
									>
										{userDetails.linkedin}
									</Link>
								</h2>
							</div>
						)}
					</div>
				</div>
			</motion.section>
		</div>
	);
};

export default SectionWrapper(Contact, "contact");
