/**
 * @file content.ts
 *
 * @description This file contains the content for the portfolio website.
 *  - introSummary: A brief summary of the developer's skills and interests.
 *  - services: An array of objects containing the services offered by the developer.
 *  - technologies: An array of objects containing the technologies used by the developer.
 *
 */

import {
	build,
	bulb,
	css,
	figma,
	folder,
	git,
	html,
	javascript,
	mongodb,
	nodejs,
	reactjs,
	redux,
	rocket,
	tailwind,
	threejs,
	typescript,
} from "@/assets";

export const introSummary = `I'm a web development enthusiast with a decade-long love affair with React.js and all
  things front-end. I have a knack for creating web applications that are not just
  interactive but also incredibly user-friendly, making the user experience seamless and
  enjoyable. With a strong grasp of web application architecture, I aim to design visually
  appealing, intuitively structured, and thoughtfully designed sites, ensuring effortless
  user journeys from start to finish.

  Aside from my passion for web development, I also enjoy dabbling in UI/UX design,
  3D modeling, and animation. Topics about space and the universe fascinate me, and I
  love to spend my free time reading about them. I'm also a huge fan of video games,`;

export const services = [
	{
		title: "Conceptualize",
		content:
			"Thinking of ideas is indeed the most challenging part of building an app from scratch. It relies mostly on one's innovation and creativity, in my case it's inspiration. Very often I find myself sparking with ideas based on personal experience or problems that I'm facing.",
		icon: bulb,
	},
	{
		title: "Design",
		content:
			"My favorite part of application development has always been designing. I may not have learned the in-depths of UI/UX but I always try to put myself into a user's shoes and imagine what would be the proper steps to make my application user-friendly, intuitive and using the proper color combination.",
		icon: folder,
	},
	{
		title: "Build",
		content:
			"It feels like building LEGO blocks with bricks but it isn't as easy as it sounds. However, having conceptualized the idea into a design, the blocks have been pretty much defined. Building the application in proper steps and applying best practices helps for a cleaner code.",
		icon: build,
	},
	{
		title: "Launch",
		content:
			"The most crucial and exciting part of a project. I take pride in meticulously checking for bugs, testing the app over and over. Getting past the seemingly endless trials and tribulations of debugging, it sure does feel rewarding reaching the finish line.",
		icon: rocket,
	},
];

export const projectsIntro = `My hobbies revolve around Photography, Coding and Designing Web/Mobile
					Applications. I try my hand on photo manipulation at times and dive
					into post processing. I've recently been fiddling with After Effects
					and play around with Motion Animation, I'd like to get a tablet
					someday and relive the time I've sketched. Bringing my ideas into
					reality has been very challenging, but the sense of completion is very
					rewarding. Along this journey of conceptual design to deployment
					always has road blocks, I find it really engaging that I learn new
					things as I solve these blocks. Unrelated to my skills, I am very much
					a beer enthusiast. I may not have a liver of steel but a good beer is
					always a must. Without futher ado, here are my projects...`;

export const technologies = [
	{
		name: "HTML 5",
		icon: html,
	},
	{
		name: "CSS 3",
		icon: css,
	},
	{
		name: "JavaScript",
		icon: javascript,
	},
	{
		name: "TypeScript",
		icon: typescript,
	},
	{
		name: "React JS",
		icon: reactjs,
	},
	{
		name: "Redux Toolkit",
		icon: redux,
	},
	{
		name: "Tailwind CSS",
		icon: tailwind,
	},
	{
		name: "Node JS",
		icon: nodejs,
	},
	{
		name: "MongoDB",
		icon: mongodb,
	},
	{
		name: "Three JS",
		icon: threejs,
	},
	{
		name: "git",
		icon: git,
	},
	{
		name: "figma",
		icon: figma,
	},
];
