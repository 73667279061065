import { useInViewStore } from "@/store/inViewStore";
import { useEffect, useRef, useState } from "react";

type UseOnScreen = (
	ref: React.RefObject<HTMLElement>,
	rootMargin?: string,
) => boolean;

export const useOnScreen: UseOnScreen = (ref, rootMargin = "0px") => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting);
			},
			{
				rootMargin,
			},
		);

		const currentElement = ref?.current;

		if (currentElement) {
			observer.observe(currentElement as Element);
		}

		return () => {
			if (currentElement) {
				observer.unobserve(currentElement as Element);
			}
		};
	}, [ref?.current, rootMargin]);

	return isVisible;
};

type UseComponentOnScreen = (component: string) => React.RefObject<HTMLElement>;

export const useComponentOnScreen: UseComponentOnScreen = (
	component: string,
) => {
	const ref = useRef<HTMLElement>(null);
	const isVisible = useOnScreen(ref);

	const setter = useInViewStore((state) => state.setSectionsInView);

	useEffect(() => {
		setter({ name: component, inView: isVisible });
	}, [isVisible, setter, component]);

	return ref;
};

interface MousePosition {
	x: number | null;
	y: number | null;
}

export const useMousePosition = () => {
	const [mousePosition, setMousePosition] = useState<MousePosition>({
		x: null,
		y: null,
	});
	useEffect(() => {
		const updateMousePosition = (ev: MouseEvent) => {
			setMousePosition({ x: ev.clientX, y: ev.clientY });
		};
		window.addEventListener("mousemove", updateMousePosition);
		return () => {
			window.removeEventListener("mousemove", updateMousePosition);
		};
	}, []);
	return mousePosition;
};
export default useMousePosition;
