import { create } from "zustand";

type SectionInView = {
	name: string;
	inView: boolean;
	position?: number;
};

export type InViewStore = {
	sectionsInView: SectionInView[];
	setSectionsInView: (section: SectionInView) => void;
	getInViewTruthy: () => SectionInView[];
};

export const useInViewStore = create<InViewStore>((set, get) => ({
	sectionsInView: [
		{ name: "home", inView: false },
		{ name: "about", inView: false },
		{ name: "work", inView: false },
		{ name: "works", inView: false },
		{ name: "contact", inView: false },
	],
	setSectionsInView: (section) =>
		set((state) => ({
			sectionsInView: state.sectionsInView.map((s) =>
				s.name === section.name ? section : { ...s, inView: false },
			),
		})),

	getInViewTruthy: () => {
		const getSectionsInView = get().sectionsInView;
		return getSectionsInView;
	},
}));
