import {
	ArchiveIcon,
	CodeIcon,
	HomeIcon,
	IdCardIcon,
	PersonIcon,
} from "@/assets/navigation";

export type NavLink = {
	name: string;
	link: string;
	icon: ({
		title,
	}: {
		title: string;
	}) => JSX.Element;
};

export const navLinks: NavLink[] = [
	{ name: "Home", link: "#home", icon: HomeIcon },
	{ name: "Myself", link: "#about", icon: PersonIcon },
	{ name: "Experience", link: "#work", icon: ArchiveIcon },
	{ name: "Works", link: "#works", icon: CodeIcon },
	{ name: "Contact", link: "#contact", icon: IdCardIcon },
];
