export const userDetails = {
	email: "francoispolo9@gmail.com",
	// bento: "https://bento.me/andasan",
	bento: "",
	github: "https://github.com/andasan",
	linkedin: "https://www.linkedin.com/in/francoispolo",
};

export * from "./content";
export { experiences } from "./experience";
export const fadeIn = (
	direction: "left" | "right" | "up" | "down" | "",
	type: string,
	delay: number,
	duration: number
) => {
	return {
		hidden: {
			x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
			y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
			opacity: 0,
		},
		show: {
			x: 0,
			y: 0,
			opacity: 1,
			transition: {
				type,
				delay,
				duration,
				ease: "easeOut",
			},
		},
	};
};
export {
	slideIn,
	staggerContainer,
	staggerHero,
	textVariant,
	zoomIn,
} from "../utils/motion";
export * from "./navigation";
export * from "./projects";
export type { Project, Tag } from "./projects";
