export const styles = {
	paddingX: "sm:px-16 px-6",
	paddingY: "sm:py-16 py-6",
	padding: "sm:px-16 px-6 sm:py-16 py-10",

	heroHeadText:
		"font-black text-white lg:text-[80px] sm:text-[60px] xs:text-[50px] text-[40px] lg:leading-[98px] mt-2",
	heroSubText:
		"text-[#dfd9ff] font-medium lg:text-[30px] sm:text-[26px] xs:text-[20px] text-[16px] lg:leading-[40px]",

	sectionHeadText:
		"text-white font-black md:text-[50px] sm:text-[40px] xs:text-[30px] text-[40px] mt-5",
	sectionSubText:
		"sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider",

	paragraphText: "text-white text-[18px] leading-[30px]",
};
