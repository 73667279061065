import close from "./close.svg";
import logo from "./logo.svg";
import menu from "./menu.svg";
import webicon from "./web-icon.png";

import build from "./services/build.png";
import bulb from "./services/bulb.png";
import folder from "./services/folder.png";
import rocket from "./services/rocket.png";

import github from "./github.png";
import css from "./tech/css.png";
import docker from "./tech/docker.png";
import figma from "./tech/figma.png";
import git from "./tech/git.png";
import html from "./tech/html.png";
import javascript from "./tech/javascript.png";
import mongodb from "./tech/mongodb.png";
import nodejs from "./tech/nodejs.png";
import reactjs from "./tech/reactjs.png";
import redux from "./tech/redux.png";
import tailwind from "./tech/tailwind.png";
import threejs from "./tech/threejs.svg";
import typescript from "./tech/typescript.png";
import video from "./tech/video.svg";

import bladeclub from "./company/bladeclub.png";
import ciccc from "./company/ciccc.png";
import cozycot from "./company/cozycot.png";
import nyp from "./company/nyp.png";

const baccarapp =
	"https://res.cloudinary.com/dehl7aiit/image/upload/v1701298630/Capture-2023-11-22-040225_vf4kqv.png";
// const anketo =
// 	"https://res.cloudinary.com/dehl7aiit/image/upload/v1690532184/portfolio-assets/anketo_cptjim.png";
// const dottax =
// 	"https://res.cloudinary.com/dehl7aiit/image/upload/v1690532185/portfolio-assets/dottax_xxjksn.png";
// const sakuravan =
// 	"https://res.cloudinary.com/dehl7aiit/image/upload/v1690532183/portfolio-assets/sakuravan_yg7ofb.jpg";
// const bewking =
// 	"https://res.cloudinary.com/dehl7aiit/image/upload/v1690532183/portfolio-assets/bewking_us7mec.png";
// const moogle =
// ("https://res.cloudinary.com/dehl7aiit/image/upload/v1690532183/portfolio-assets/moogle_hbfmpf.png");
import anketo from "./projects/anketo.png";
// import baccarapp from "./projects/baccarapp.png";
import bewking from "./projects/bewking.png";
import cornermail from "./projects/cornermail.jpeg";
import kabaljero from "./projects/kabaljero.png";
import moogle from "./projects/moogle.png";
import sakuravan from "./projects/sakuravan.jpg";
import multidoc from "./projects/multidoc.png";

export {
	logo,
	webicon,
	menu,
	close,
	build,
	bulb,
	folder,
	rocket,
	github,
	css,
	docker,
	figma,
	git,
	html,
	javascript,
	mongodb,
	nodejs,
	reactjs,
	redux,
	tailwind,
	typescript,
	threejs,
	video,
	ciccc,
	bladeclub,
	cozycot,
	nyp,
	baccarapp,
	cornermail,
	sakuravan,
	moogle,
	bewking,
	anketo,
	kabaljero,
	multidoc,
};
