import styles from "./hero.module.css";

import SectionWrapper from "@/components/modules/wrapper/SectionWrapper";
import { cn } from "@/utils";
import { useComponentOnScreen } from "@/utils/hooks";

const Hero = () => {
	const ref = useComponentOnScreen("home");

	return (
		<section
			ref={ref}
			id="home"
			className="w-full h-[110vh] mx-auto flex flex-col justify-center"
		>
			<div className="sm:px-16 px-6 inset-0 max-w-7xl mx-auto flex flex-row mb-20 items-start gap-5">
				<div className=" drop-shadow-lg">
					<h1
						className={cn(
							styles.headerAnimation,
							"font-black text-white lg:text-[80px] text-[40px] lg:leading-[98px] mt-2",
						)}
					>
						Hi, I'm <span className="text-[#ffe222]">Francois</span>
					</h1>

					<p
						className={cn(
							styles.staggeredText,
							"text-white text-[20px] lg:text-[40px] lg:leading-[48px] mt-2",
						)}
					>
						<span style={{ "--animation-order": 1 } as React.CSSProperties}>
							I am a&nbsp;
							<span className="text-[#ffe222]">frontend developer,&nbsp;</span>
						</span>
						<span style={{ "--animation-order": 2 } as React.CSSProperties}>
							a&nbsp;
							<span className="text-[#ffe222]">graphic designer,&nbsp;</span>
						</span>
						<span style={{ "--animation-order": 3 } as React.CSSProperties}>
							an&nbsp;<span className="text-[#ffe222]">illustrator&nbsp;</span>
						</span>
						<span style={{ "--animation-order": 4 } as React.CSSProperties}>
							and a&nbsp;<span className="text-[#ffe222]">shutterbug</span>.
						</span>
					</p>
				</div>
			</div>

			<div className="w-full flex justify-center items-center">
				<div id="mouse-scroll">
					<div>
						<span className="down-arrow-1" />
						<span className="down-arrow-2" />
						<span className="down-arrow-3" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default SectionWrapper(Hero, "home");
