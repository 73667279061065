"use client";

import { useInViewStore } from "@/store/inViewStore";
import Link from "next/link";
import { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from "react";

import styles from "./navi.module.css";

import { navLinks } from "@/constants/navigation";
import { cn } from "@/utils";

function NaviButton({
	indexValue,
	navItem,
}: {
	indexValue: number;
	navItem: {
		name: string;
		link: string;
		icon: ({ title }: { title: string }) => JSX.Element;
	};
}) {
	return (
		<li>
			<Link
				aria-label={navItem.name}
				role="button"
				href={navItem.link}
				className={`${indexValue === 1 && styles.active}`}
			>
				<div className={styles.icon}>
					{navItem.icon && <navItem.icon title={navItem.name} />}
				</div>
				<div className={styles.text}>{navItem.name}</div>
			</Link>
		</li>
	);
}

export default function Navi() {
	const [lastInView, setLastInView] = useState(0);
	const indicatorRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<HTMLUListElement>(null);
	const sectionsInView = useInViewStore((state) => state.sectionsInView);

	useEffect(() => {
		const getIndexInView = sectionsInView.findIndex(
			(section) => section.inView === true,
		);

		if (getIndexInView === -1) {
			setLastInView(getIndexInView);
			return;
		}

		indicatorRef?.current?.style.setProperty(
			"--position",
			getIndexInView.toString(),
		);

		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		const allAnchors = Array.from(listRef.current?.querySelectorAll("a")!);
		const anchor = allAnchors[getIndexInView];

		for (const elem of allAnchors) {
			elem.classList.remove(styles.active);
		}

		anchor?.classList.add(styles.active);
	}, [sectionsInView]);

	const handleNavEvent = (
		e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
	) => {
		let anchor: HTMLAnchorElement | null = null;

		if (
			e.target instanceof HTMLAnchorElement ||
			e.target instanceof SVGElement
		) {
			anchor = e.target.closest("a");
		}

		if (anchor != null) {
			// biome-ignore lint/style/noNonNullAssertion: <explanation>
			const allAnchors = Array.from(listRef.current?.querySelectorAll("a")!);
			const index = allAnchors.indexOf(anchor);

			indicatorRef?.current?.style.setProperty("--position", index.toString());

			for (const elem of allAnchors) {
				elem.classList.remove(styles.active);
			}

			anchor.classList.add(styles.active);
		}
	};

	return (
		<div className={styles.outerWrapper}>
			<div className={styles.innerWrapper}>
				<div className="w-full sticky top-0 z-50">
					<nav
						className={cn(
							"mx-auto max-w-md rounded-2xl bg-gray-600 bg-opacity-50 px-2 py-0 shadow-lg backdrop-blur-md max-sm:px-8",
							styles.navbarContainer,
						)}
						onClick={handleNavEvent}
						onKeyUp={handleNavEvent}
						onKeyDown={handleNavEvent}
					>
						<ul className={styles.list} ref={listRef}>
							<li>
								<div
									style={{ "--position": 0 } as React.CSSProperties}
									ref={indicatorRef}
									className={styles.indicator}
									role="button"
									aria-label="Navigation Bar"
								>
									{/* <div className={styles.corners} /> */}
								</div>
							</li>
							{navLinks.map((navItem, index) => (
								<NaviButton
									key={navItem.name}
									navItem={navItem}
									indexValue={index + 1}
								/>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</div>
	);
}
