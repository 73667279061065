import { motion } from "framer-motion";
import Image from "next/image";
import { StaticImageData } from "next/image";
import { useEffect } from "react";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";

import SectionWrapper from "@/components/modules/wrapper/SectionWrapper";
import { experiences } from "@/constants";
import { useComponentOnScreen } from "@/utils/hooks";
import { textVariant } from "@/utils/motion";
import { styles } from "@/utils/styles";

type ExperienceCardProps = {
	experience: {
		id: string;
		date: string;
		title: string;
		company_name: string;
		location: string;
		icon: StaticImageData;
		iconBg: string;
		points: string[];
	};
};

const ExperienceCard = ({ experience }: ExperienceCardProps) => {
	return (
		<VerticalTimelineElement
			visible={true}
			contentStyle={{
				background: "#1d183699",
				color: "#fff",
				backdropFilter: "blur(12px)",
			}}
			contentArrowStyle={{ borderRight: "7px solid #232631" }}
			date={experience.date}
			iconStyle={{ background: experience.iconBg }}
			icon={
				<div className="relative flex justify-center items-center w-full h-full">
					<Image
						src={experience.icon.src}
						alt={experience.company_name}
						width={30}
						height={30}
						style={{
							objectFit: "cover",
						}}
					/>
				</div>
			}
		>
			<div>
				<h3 className="text-white text-[24px] font-bold">{experience.title}</h3>
				<p
					className="text-secondary text-[16px] font-semibold"
					style={{ margin: 0 }}
				>
					{experience.company_name} -{" "}
					<span className="text-[12px] text-yellow-200">
						{experience.location}
					</span>
				</p>
			</div>

			<ul className="mt-5 ml-5 list-disc space-y-2">
				{experience.points
					.map((point) => ({ id: crypto.randomUUID(), desc: point }))
					.map((point) => (
						<li
							key={point.id}
							className="text-white-100 text-[14px] pl-1 tracking-wider"
						>
							{point.desc}
						</li>
					))}
			</ul>
		</VerticalTimelineElement>
	);
};

const Experience = () => {
	const ref = useComponentOnScreen("work");

	useEffect(() => {
		const link = document.createElement("link");
		link.rel = "stylesheet";
		link.href = "experience.min.css";
		link.type = "text/css";
		link.media = "all";

		document.head.appendChild(link);

		return () => {
			// Cleanup if needed
			document.head.removeChild(link);
		};
	}, []);

	return (
		<section id="work" ref={ref}>
			<div className="rounded-xl bg-gray-800 bg-opacity-50 px-16 py-10 shadow-lg backdrop-blur-md max-sm:px-8">
				<motion.div variants={textVariant()}>
					<p className={styles.sectionSubText}>What I have done</p>
					<h2 className={styles.sectionHeadText}>Work Experience</h2>
				</motion.div>
			</div>

			<div className="mt-20 flex flex-col">
				<VerticalTimeline lineColor="">
					{experiences.map((experience) => (
						<ExperienceCard key={experience.id} experience={experience} />
					))}
				</VerticalTimeline>
			</div>
		</section>
	);
};

export default SectionWrapper(Experience, "work");
