"use client";

import { SpaceBoi } from "@/components/modules/3d-components/space";
import Navi from "@/components/modules/navi";

export default function Home() {
	return (
		<main className="h-screen w-screen">
			<Navi />
			<SpaceBoi />
		</main>
	);
}
