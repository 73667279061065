"use client";

import { Dispatch, useEffect, useRef } from "react";

// import Skeleton from "@/components/layout/Skeleton"
import About from "@/components/sections/About";
import Contact from "@/components/sections/Contact";
import Experience from "@/components/sections/Experience";
import Hero from "@/components/sections/Hero";
import Works from "@/components/sections/Works";

interface OverlayProps {
	setPages?: Dispatch<React.SetStateAction<number>>;
}

const Overlay = ({ setPages }: OverlayProps) => {
	const mainRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// if (mainRef.current && setPages) {
		// 	console.dir(mainRef.current);
		// 	setPages(mainRef?.current?.clientHeight / window.innerHeight);
		// }

		if (!mainRef.current || !setPages) return;

		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry.target === mainRef.current) {
					setPages(entry.target.clientHeight / window.innerHeight);
				}
			}
		});

		resizeObserver.observe(mainRef?.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, [setPages]);

	return (
		<div ref={mainRef}>
			<Hero />
			<About />
			<Experience />
			<Works />
			<Contact />

			<div className="text-white p-2 text-xs bg-black bg-opacity-50">
				<p>
					<a href="https://skfb.ly/oyXLG">space boi</a> by silvercrow101 is
					licensed under{" "}
					<a href="http://creativecommons.org/licenses/by-nc/4.0/">
						Creative Commons Attribution-NonCommercial
					</a>{" "}
					| © 2023-2024 Andasan
				</p>
			</div>
		</div>
	);
};

export default Overlay;
