"use client";

import { OrbitControls, Scroll, ScrollControls } from "@react-three/drei";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import { Suspense, useRef, useState } from "react";
import { Mesh, Vector3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

import CanvasLoader from "@/components/modules/loader/Loader";
import Overlay from "@/pages/overlay";
import { lerp } from "three/src/math/MathUtils.js";

function MeshComponent() {
	const fileUrl = "/space_boi.glb";
	// biome-ignore lint/style/noNonNullAssertion: we are certain that mesh.current is not null or undefined when we access it in effects
	const mesh = useRef<Mesh>(null!);
	const gltf = useLoader(GLTFLoader, fileUrl);
	// const { pointer, camera } = useThree();
	// const vec = new Vector3();

	useFrame(({ pointer, viewport }) => {
		mesh.current.position.x = -0.5;
		mesh.current.position.z = -0.5;
		mesh.current.position.y = -1.9;
		const x = (pointer.x * viewport.width) / 50.5;
		// const y = (pointer.y * viewport.height) / 2.5;
		// mesh.current.rotation.x = -0.001;
		// mesh.current.rotation.y = -10;
		// mesh.current.rotation.z = 12;
		// mesh.current.lookAt(x - 13, y - 40, 30);
		// mesh.current.rotation.
		mesh.current.position.x = lerp(mesh.current.position.x, 0, 0.025);
		mesh.current.position.y = lerp(mesh.current.position.y, 0, 0.025);
		mesh.current.position.z = lerp(mesh.current.position.z, 0, 0.025);
		// mesh.current.material.color.lerp(hovered ? lime : black, 0.05);
	});

	useFrame(() => {
		mesh.current.rotation.y += 0.001;
		mesh.current.rotation.x = -0.001;
		mesh.current.position.z = -0.5;
		mesh.current.position.y = -2.9;

		// camera.position.lerp(
		// 	vec.set(pointer.x, pointer.y, camera.position.z),
		// 	0.05,
		// );
		// camera.lookAt(0, 0, 0);
	});

	return (
		<mesh ref={mesh}>
			<primitive object={gltf.scene} />
		</mesh>
	);
}

function Rig() {
	const { camera, pointer } = useThree();
	const vec = new Vector3();

	return useFrame(() => {
		camera.position.lerp(
			vec.set(pointer.x, pointer.y, camera.position.z),
			0.05,
		);
		camera.lookAt(0, 0, 0);
	});
}

export function SpaceBoi() {
	const [pages, setPages] = useState(0);

	return (
		<div className="flex justify-center items-center h-screen">
			<Canvas>
				<color attach="background" args={[0x000000]} />
				<ScrollControls pages={pages} damping={0.1}>
					<OrbitControls
						maxPolarAngle={2}
						minPolarAngle={1.5}
						maxAzimuthAngle={(Math.PI / 2) * 0.5}
						minAzimuthAngle={(-Math.PI / 2) * 0.5}
						enableZoom={false}
						// minDistance={8}
						// maxDistance={12}
					/>
					<ambientLight />
					<pointLight position={[10, 10, 10]} />
					<Suspense fallback={<CanvasLoader />}>
						<MeshComponent />
					</Suspense>
					<Scroll html style={{ width: "100vw", height: "100%" }}>
						<Overlay setPages={setPages} />
					</Scroll>
				</ScrollControls>
				<Rig />
			</Canvas>
		</div>
	);
}
