import { Variants } from 'framer-motion';

type TextVariantProps = (delay?: number) => {
	hidden: {
		y: number;
		opacity: number;
	};
	show: {
		y: number;
		opacity: number;
		transition: {
			type: string;
			duration: number;
			delay: number;
		};
	};
};

export const textVariant: TextVariantProps = (delay) => {
	return {
		hidden: {
			y: -50,
			opacity: 0,
		},
		show: {
			y: 0,
			opacity: 1,
			transition: {
				type: "spring",
				duration: 1.25,
				delay: delay || 0,
			},
		},
	};
};

type FadeInProps = (
	direction: "left" | "right" | "up" | "down" | "",
	type: string,
	delay: number,
	duration: number,
) => Variants;

export const fadeIn: FadeInProps = (direction, type, delay, duration) => {
	return {
		hidden: {
			x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
			y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
			opacity: 0,
		},
		show: {
			x: 0,
			y: 0,
			opacity: 1,
			transition: {
				type,
				delay,
				duration,
				ease: "easeOut",
			},
		},
	};
};

type ZoomInProps = (
	delay?: number,
	duration?: number,
) => {
	hidden: object;
	show: {
		scale: number;
		opacity: number;
		transition: {
			type: string;
			delay: number;
			duration: number;
			ease: string;
		};
	};
};

export const zoomIn: ZoomInProps = (delay, duration) => {
	return {
		hidden: {
			scale: 0,
			opacity: 0,
		},
		show: {
			scale: 1,
			opacity: 1,
			transition: {
				type: "tween",
				delay: delay || 0,
				duration: duration || 0.5,
				ease: "easeOut",
			},
		},
	};
};

type SlideInProps = (
	direction?: string,
	type?: string,
	delay?: number,
	duration?: number,
) => {
	hidden: object;
	show: {
		x: number;
		y: number;
		transition: {
			type: string;
			delay: number;
			duration: number;
			ease: string;
		};
	};
};

export const slideIn: SlideInProps = (direction, type, delay, duration) => {
	return {
		hidden: {
			x: direction === "left" ? "-100%" : direction === "right" ? "100%" : 0,
			y: direction === "up" ? "100%" : direction === "down" ? "100%" : 0,
		},
		show: {
			x: 0,
			y: 0,
			transition: {
				type: type || "tween",
				delay: delay || 0,
				duration: duration || 0.5,
				ease: "easeOut",
			},
		},
	};
};

type StaggerContainerProps = (
	staggerChildren?: number,
	delayChildren?: number,
) => {
	hidden: object;
	show: {
		transition: {
			staggerChildren: number | undefined;
			delayChildren: number | undefined;
		};
	};
};

export const staggerContainer: StaggerContainerProps = (
	staggerChildren,
	delayChildren,
) => {
	return {
		hidden: {},
		show: {
			transition: {
				staggerChildren: staggerChildren,
				delayChildren: delayChildren || 0,
			},
		},
	};
};

type StaggerHeroProps = {
	hidden: {
		opacity: number;
	};
	show: {
		opacity: number;
		transition: {
			delayChildren: number;
			staggerChildren: number;
		};
	};
};

export const staggerHero: StaggerHeroProps = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			delayChildren: 1.5,
			staggerChildren: 0.5,
		},
	},
};
